<template>
    <OMediaQueryProvider v-slot="{ isTouch }">
        <ActiveFiltersTouch v-if="isTouch" :dataObject="dataObject" :filterObject="filterObject"/>

        <ActiveFiltersPointer v-if="!isTouch" :dataObject="dataObject" :filterObject="filterObject" :hideSavedFilters="hideSavedFilters" :hideHeader="hideHeader"
            :responsiveLayout="responsiveLayout"/>
        <slot name="filterBottom"></slot>
     </OMediaQueryProvider>
</template>

<script setup>
    import { OMediaQueryProvider } from 'o365-ui-components';
    import { useAsyncComponent } from 'o365-vue-utils';

    const ActiveFiltersTouch = useAsyncComponent('./components.ActiveFiltersTouch.vue', { importFn: () => import('./components.ActiveFiltersTouch.vue') });
    const ActiveFiltersPointer = useAsyncComponent('./components.ActiveFiltersPointer.vue', { importFn: () => import('./components.ActiveFiltersPointer.vue') });

    const props = defineProps({
        filterObject: Object,
        dataObject:Object,
        gridRef:Object,
        hideSavedFilters:{
            type:Boolean,
            default:false 
        },
        hideHeader: Boolean,
        responsiveLayout: Boolean
    });

    const filterObject = props.filterObject ?? props.dataObject?.filterObject ?? props.gridRef.dataObject?.filterObject ?? props.gridRef.filterObject;
</script>

<style scoped>
    .filter-list-bottom-spacing {
        padding-bottom: 40px;
    }
</style>